import React from 'react'
import PropTypes from 'prop-types';

import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';

import axios from 'axios'

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
})

class Accounts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accountName: "",
      accounts: []
    };

    this.getAccounts()
}


  updateInputAccountName = (event) => {
    this.setState({ accountName: event.target.value })
  }
  addAccount = () => {

    if (!this.state.accountName) {
      return;
    }

    let data = {
      name: this.state.accountName
    }
    this.setState({ accountName: "" })
    axios.post('/api/accounts', data).then(res => {
      this.getAccounts()
    }).catch(error => {
      console.log(error.response)
    })
  }

  getAccounts = () => {
    axios.get('/api/accounts').then(res => {
      if (res.data) {
        this.setState({
          accounts: res.data
        })
      }
    }).catch(error => {
      console.log(error.response)
    })
  }

  useAccount = (name) => {
    axios.put('/api/accounts', { name }).then(res => {
      if (res.data) {
        this.getAccounts()
      }
    }).catch(error => {
      console.log(error.response)
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <TextField label="Account Name" value={this.state.accountName} onChange={this.updateInputAccountName} />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={this.addAccount}
        >
          Add Account
      </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<SyncIcon />}
          onClick={this.getAccounts}
        >
          Refresh
      </Button>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Account</TableCell>
              <TableCell align="center">Used</TableCell>
              <TableCell align="center">Acction</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.accounts.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" align="center">
                  {row.name}
                </TableCell>
                <TableCell align="left"> {row.logged.map((used, index) => (
                  <div key={index}>
                    <Typography>{`--------------------${index + 1}----------------------`}</Typography>
                    <Typography color="textSecondary">{"Logged Time: " + new Date(used.startTime).toLocaleString('vn-VN')}</Typography>
                    <Typography color="textSecondary">{"Due Time: " + new Date(used.endTime).toLocaleString('vn-VN')}</Typography>
                    <Typography color="textPrimary">{"Remaining Time: " + new Date(used.remainingTime).toISOString().substr(11, 8)}</Typography>
                  </div>
                ))}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => this.useAccount(row.name)}
                  >
                    Use
                </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

Accounts.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Accounts)