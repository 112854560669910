import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import { withStyles, Grid, Paper, Typography, InputBase, Divider, IconButton, Chip, LinearProgress,
   Fab, Table, TableHead, TableRow, TableCell, TableBody, useMediaQuery, Hidden, TableContainer } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { makeStyles } from '@material-ui/styles';

const attributes = {
  "Technical": {
    "Cor": "Corners",
    "Cro": "Crossing",
    "Dri": "Dribbling",
    "Fin": "Finishing",
    "Fir": "First Touch",
    "Fre": "Free Kick Taking",
    "Hea": "Heading",
    "Lon": "Long Shots",
    "L Th": "Long Throws",
    "Mar": "Marking",
    "Pas": "Passing",
    "Pen": "Penalty Taking",
    "Tck": "Tackling",
    "Tec": "Technique"
  },
  "Mental": {
    "Agg": "Aggression",
    "Ant": "Anticipation",
    "Bra": "Bravery",
    "Cmp": "Composure",
    "Cnt": "Concentration",
    "Dec": "Decisions",
    "Det": "Determination",
    "Fla": "Flair",
    "Ldr": "Leadership",
    "OtB": "Off the Ball",
    "Pos": "Positioning",
    "Tea": "Teamwork",
    "Vis": "Vision",
    "Wor": "Work Rate"
  },
  "Physical": {
    "Acc": "Acceleration",
    "Agi": "Agility",
    "Bal": "Balance",
    "Jum": "Jumping Reach",
    "Nat": "Natural Fitness",
    "Pac": "Pace",
    "Sta": "Stamina",
    "Str": "Strength"
  },
  "Goalkeeping": {
    "Aer": "Aerial Reach",
    "Cmd": "Command of Area",
    "Com": "Communication",
    "Ecc": "Eccentricity",
    "Fir": "First Touch",
    "Han": "Handling",
    "Kic": "Kicking",
    "1v1": "One on Ones",
    "Pas": "Passing",
    "Ref": "Reflexes",
    "Rus": "Rushing Out",
    "Pun": "Tendency to Punch",
    "Thr": "Throwing",
    "Tec": "Technique"
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  divider: {
    margin: 10,
  },
});

const playerProfileStyles = makeStyles(theme => ({
  attributePaper: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  attributeValue: {
    width: '40px',
  },
  attributeName: {
    width: '-webkit-fill-available',
  },
  divider: {
    margin: 10,
  },
  chipPros: {
    margin: theme.spacing(0.5),
    backgroundColor: "green"
  },
  chipCos: {
    margin: theme.spacing(0.5),
    backgroundColor: "#96207d"
  },
  rating: {
    display: "grid"
  },
  playerInfo: {
    padding: theme.spacing(1)
  },
  playerTraits: {
    paddingLeft: theme.spacing(1)
  },
  playerReview: {
    padding: theme.spacing(1),
    minHeight: `${36 * 8}px`
  }
}));

function PlayerProfile(props) {

  const classes = playerProfileStyles(props)
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { player } = props

  const getColor = (value) => {
    if (value > 15)
      return '#96207d'
    else if (value > 10)
      return 'green'
    else if (value < 6)
      return '#2b2b2b'

  }

  function FormColumn(props) {
    return (
      <React.Fragment>
        {Object.keys(props.attributes).map((key, index) => (
          <Grid item key={key}>
            <Paper className={classes.attributePaper}>
              <Typography className={classes.attributeName} variant="subtitle1">
                {matches ? props.attributes[key] : key}</Typography>
              <Paper className={classes.attributeValue} style={{ background: props.color(player[key]) }}>
                <Typography align="center" variant="subtitle1" >{player[key] ? player[key] : "-"}</Typography>
              </Paper>
            </Paper>
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  return (
    <div>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={2} sm={1} className={classes.rating} >
          <IconButton color="primary" className={classes.button} aria-label="player up vote">
            <KeyboardArrowUpIcon fontSize="large" />
          </IconButton>
          <Typography align="center" variant="h6">{Player.Rating > 10 ? Player.Rating : "-"}</Typography>
          <IconButton color="primary" className={classes.button} aria-label="player down vote">
            <KeyboardArrowDownIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={10} sm={11} lg={4}>
          <Typography variant="h5" color="textPrimary" align="center">{player.Name}</Typography>
          <Typography variant="subtitle2" color="textSecondary" align="center" >{player.DOB}</Typography>
          <Typography variant="subtitle2" color="textSecondary" align="center" >{player.Position} - {player.Value}</Typography>
          <Typography variant="subtitle2" color="textSecondary" align="center">{player.NoB}</Typography>
          <Typography variant="subtitle2" color="textSecondary" align="center">{player.Club} - {player.Division}</Typography>
        </Grid>
        <Grid item xs={2} sm={2} lg={1} className={classes.playerInfo} align="center">
          <Fab color="primary" size="small" aria-label="add" className={classes.fab}>
            <AddIcon />
          </Fab>
        </Grid>
        <Grid container item direction="column" xs={10} sm={10} lg={6}>
          <Grid item >
            <Chip label="Đẹp trai" className={classes.chipPros} color="primary" />
            <Chip label="Dễ thương" className={classes.chipPros} color="primary" />
            <Chip label="Tốt bụng" className={classes.chipPros} color="primary" />
          </Grid>
          <Grid item >
            <Chip label="Quá đẹp trai" className={classes.chipCos} color="secondary" />
            <Chip label="Quá dễ thương" className={classes.chipCos} color="secondary" />
            <Chip label="Quá tốt bụng" className={classes.chipCos} color="secondary" />
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} orientation="horizontal" />

      <Grid container alignItems="flex-start" spacing={1} >
        <Grid container item xs={6} sm={4} lg={3} direction="column">
          <FormColumn attributes={player.Position && player.Position.includes("GK") ? attributes["Goalkeeping"] : attributes["Technical"]} color={getColor} />
        </Grid>
        <Grid container item xs={6} sm={4} lg={3} direction="column" >
          <FormColumn attributes={attributes["Mental"]} color={getColor} />
        </Grid>
        <Grid container item xs={12} sm={4} lg={3} direction="row">
          <Grid item xs={6} sm={12} lg={12}>
            <FormColumn attributes={attributes["Physical"]} color={getColor} />
          </Grid>
          <Grid item xs={6} sm={12} lg={12} className={classes.playerTraits}>
            <Divider className={classes.divider} orientation="horizontal" variant="middle" />
            <Typography variant="subtitle1" color="textPrimary" >Height: {player.Height}</Typography>
            <Typography variant="subtitle1" color="textPrimary" >Weight: {player.Weight}</Typography>
            <Typography variant="subtitle1" color="textPrimary" >Preferred Foot: {player["Preferred Foot"]}</Typography>
            <Typography variant="subtitle1" color="textPrimary" >Personality: {player.Personality}</Typography>
            <Divider className={classes.divider} orientation="horizontal" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} >
          <Paper className={classes.playerReview}>
            <Typography variant="h6" color="textPrimary" >FMVN Review</Typography>
            <Divider className={classes.divider} orientation="horizontal" />
            <Typography variant="body1" color="textPrimary" gutterBottom>{player.review}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </div >
  )
}

const searchBarStyles = makeStyles(theme => ({
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,

  },
  searchIconButton: {
    padding: 10,
  },
  searchBar: {
    display: 'flex'
  },
  divider: {
    margin: 10,
  },
}));

function SearchBarComp(props) {
  const [searchText, setSearchText] = useState("")
  const [searhing, isSearching] = useState(false)
  const classes = searchBarStyles(props)

  const getPlayer = async () => {
    if (!searchText || !searchText.trim()) {
      props.viewPlayer(null)
      return
    }
    isSearching(true)
    await props.searchPlayer(searchText)
    isSearching(false)
  }

  return (
    <div >
      <Paper className={classes.searchBar} >
        <InputBase
          className={classes.searchInput}
          placeholder="Search Player"
          inputProps={{ 'aria-label': 'search player' }}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onKeyPress={target => target.charCode === 13 ? getPlayer() : null}
        />
        <IconButton className={classes.searchIconButton} aria-label="search"
          onClick={getPlayer} >
          <SearchIcon />
        </IconButton>
      </Paper>
      {searhing ? <LinearProgress variant="query" color="secondary" className={classes.progress} /> : null}
    </div >
  )
}

const SearchBar = withSnackbar(SearchBarComp)

function TopScouted(props) {
  const classes = playerProfileStyles(props)

  return (
    <TableContainer>
      <Table className={classes.playerInfo} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Position</TableCell>
            <Hidden mdDown>
              <TableCell>Value</TableCell>
              <TableCell>Club</TableCell>
              <TableCell>Nation</TableCell>
              <TableCell>Rating</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.list.map((item, index) =>
            <TableRow key={index} hover >
              <TableCell onClick={() => props.searchPlayer(item.Name)}>
                {item.Name}
              </TableCell>
              <TableCell>{item.Age}</TableCell>
              <TableCell>{item.Position}</TableCell>
              <Hidden mdDown>
                <TableCell>{item.Value}</TableCell>
                <TableCell>{item.Club}</TableCell>
                <TableCell>{item.NoB}</TableCell>
                <TableCell>{item.Rating}</TableCell>
              </Hidden>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

class Player extends Component {
  constructor(props) {
    super(props)
    this.state = {
      player: undefined,
      topScoutedList: []
    }

    this.getTopScouted()
  }

  viewPlayer = (player) => {
    this.setState({ player: player })
  }

  searchPlayer = (name) => {
    return new Promise(resolve => axios.get(`/api/players`, {
      params: {
        name: name
      }
    }).then(res => {
      if (res.data) {
        this.viewPlayer(res.data)
        resolve(1)
      }
    }).catch(error => {
      if (error.response && error.response.status === 404)
        this.props.enqueueSnackbar(`${name} is not found`, { variant: "info", autoHideDuration: 2000 })
      else {
        console.log(error.response)
        this.props.enqueueSnackbar(`Failed to retrieve player: ${name}`, { variant: "error", autoHideDuration: 2000 })
      }
      resolve(0)
    }))
  }

  getTopScouted = () => {
    axios.get(`/api/players/topscouted`).then(res => {
      if (res.data) {
        this.setState({ topScoutedList: res.data })
      }
    }).catch(error => {
      console.log(error.response)
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <SearchBar viewPlayer={this.viewPlayer} searchPlayer={this.searchPlayer} />

        <Divider className={classes.divider} orientation="horizontal" />

        {this.state.player ?
          <PlayerProfile player={this.state.player} /> :
          <TopScouted searchPlayer={this.searchPlayer} list={this.state.topScoutedList} />}
      </div >
    )
  }
}

Player.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(Player))