import { defineAbility  } from '@casl/ability'

export default defineAbility(can => {
  can('view', 'Home')
  can('view', 'FMVN')
  can('view', 'Scouting')
  can('view', 'Download')
  can('view', 'About')
  can('view', 'Login')
  can('add', 'chip')
})