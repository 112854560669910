import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'
import axios from 'axios'

import {
    withStyles, makeStyles, Box, Grid, Paper, IconButton, Typography, Tooltip, Menu, MenuItem, Hidden,
    List, ListItem, ListItemText, ListItemAvatar, Avatar, Radio, RadioGroup, FormControlLabel, FormControl,
    TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@material-ui/core'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import FolderIcon from '@material-ui/icons/Folder'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import ArrowBackIosOutlined from '@material-ui/icons/ArrowBackIosOutlined'

const styles = theme => ({
    topBar: {
        position: "sticky",
        [theme.breakpoints.up('sm')]: {
            top: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(1)}px)`,
        },
        [theme.breakpoints.down('sm')]: {
            top: `${theme.mixins.toolbar.minHeight}px`,
        },
        marginBottom: theme.spacing(2),
    },
})

class Download extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            currentPath: "",
            loaded: 0,
            viewMode: "list",
            isChallengeDialogOpen: false,
            challengeAnwser: "",
            currentDownloadFilePath: "",
            challengeAnwserIsRight: true,
        }
    }

    componentDidMount = () => {
        this.getFiles(this.state.currentPath)
        let pathname = window.location.pathname
        if (pathname && pathname.startsWith('/download/')) {
            this.openChallengeDialog(pathname.substring(9))
        }
    }

    getFiles = (path) => {
        axios.get(`/api/files?path=${path}`).then(res => {
            if (res.data) {
                this.setState({
                    files: res.data,
                    currentPath: path
                })
            }
        }).catch(error => {
            if (error.response && error.response.data) {
                this.props.enqueueSnackbar(error.response.data.message, { variant: "error", autoHideDuration: 2000 })
            }
        })
    }

    backToPrevPath = () => {
        let path = this.state.currentPath.substring(0, this.state.currentPath.lastIndexOf("/"))
        this.getFiles(path)
    }

    selectViewMode = (event) => {
        this.setState({ viewMode: event.target.value })
    }

    buildFileDownloadURL = (path) => {
        return `${window.location.origin}/download${path}`
    }

    openChallengeDialog = (path) => {
        this.setState({ isChallengeDialogOpen: true, currentDownloadFilePath: path })
    }

    closeChallengeDialog = (path) => {
        this.setState({ isChallengeDialogOpen: false, currentDownloadFilePath: "" })
    }

    downloadFile = () => {
        if (this.state.challengeAnwser === '69') {
            window.open(`${window.location.origin}/api/download?path=${this.state.currentDownloadFilePath}`)
            this.setState({ isChallengeDialogOpen: false, challengeAnwser: '', currentDownloadFilePath: '' })
        }
        else {

        }
    }

    updateChallengeAnwser = (event) => {
        this.setState({ challengeAnwser: event.target.value, challengeAnwserIsRight: event.target.value === '69' || event.target.value === '' ? true : false })
    }

    render() {
        const { classes } = this.props
        return (
            <div>
                <Dialog open={this.state.isChallengeDialogOpen} onClose={this.closeChallengeDialog}>
                    <DialogTitle id="form-dialog-title">Human Checking</DialogTitle>
                    <DialogContent style={{ overflowY: 'hidden' }}>
                        <TextField variant="outlined" label="6+9=? (Hint: It's not math)" type='number' value={this.state.challengeAnwser} onChange={this.updateChallengeAnwser} />
                        {this.state.challengeAnwserIsRight ? null : <Typography variant='subtitle2'>The answer is wrong!</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.downloadFile} color="primary" variant="contained">Download</Button>
                    </DialogActions>
                </Dialog>
                <Box zIndex="1" className={classes.topBar}>
                    <Paper  >
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <IconButton aria-label="back" color="secondary" onClick={this.backToPrevPath}>
                                    <ArrowBackIosOutlined fontSize="large"></ArrowBackIosOutlined>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="view" name="view mode" value={this.state.viewMode} onChange={this.selectViewMode}>
                                        <FormControlLabel value="list" control={<Radio />} label="List View" />
                                        <FormControlLabel value="tiles" control={<Radio />} label="Tiles View" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
                <Box zIndex="0">
                    {
                        this.state.viewMode === "list" ?
                            <List>
                                {this.state.files.map((item, index) => (
                                    <Box style={{ display: "flex" }} key={index + item.name}>
                                        <ListItem button divider onClick={() => item.isFile ? this.openChallengeDialog(item.path) : this.getFiles(item.path)}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {item.isFile ?
                                                        <InsertDriveFileIcon color="secondary"></InsertDriveFileIcon>
                                                        :
                                                        <FolderIcon color="primary"></FolderIcon>
                                                    }
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={item.name} secondary={item.isFile ? item.size + " MB" : null} primaryTypographyProps={{ noWrap: true }} />

                                        </ListItem>
                                        <Hidden mdDown>
                                            {item.isFile ? <ItemMenu url={this.buildFileDownloadURL(item.path)}></ItemMenu> : null}
                                        </Hidden>
                                    </Box>
                                ))}
                            </List>
                            :
                            <Grid container direction="row" alignItems="center" spacing={1}>
                                {this.state.files.map((item, index) => (
                                    <Grid item key={index} >
                                        <FolderItem name={item.name} handleClick={item.isFile ? this.downloadFile : this.getFiles} path={item.path} isFile={item.isFile} size={item.size} />
                                    </Grid>
                                ))}
                            </Grid>
                    }
                </Box>
            </div>
        )
    }
}

Download.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withSnackbar(withStyles(styles)(Download))

const folderStyle = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    hugeIcon: {
        width: 110,
        height: 110
    },
    title: {
        width: 110
    }
}))

function FolderItem(props) {
    const classes = folderStyle(props)
    return (
        <div>
            <Tooltip title={props.isFile ? `${props.name} - ${props.size} MB` : props.name} arrow>
                <Paper elevation={3} className={classes.paper} onClick={() => props.handleClick(props.path)}>
                    {props.isFile ?
                        <InsertDriveFileIcon fontSize="large" className={classes.hugeIcon}></InsertDriveFileIcon>
                        :
                        <FolderIcon fontSize="large" className={classes.hugeIcon}></FolderIcon>
                    }
                    <Typography variant="caption" noWrap align="center" className={classes.title}>{props.name}</Typography>
                </Paper>
            </Tooltip>
        </div>
    )
}

function ItemMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null)

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const copyItemLink = (url) => {
        closeMenu()
        navigator.clipboard.writeText(url)
    }

    const open = Boolean(anchorEl)

    return (
        <div>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={openMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu id="item-menu" anchorEl={anchorEl} keepMounted open={open} onClose={closeMenu}>
                <MenuItem onClick={() => copyItemLink(props.url)}>Copy Link</MenuItem>
            </Menu>
        </div>
    )
}
