import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { registerUser, loginUser } from "../../actions/authActions";
import { withSnackbar } from 'notistack';

import { Button, FormControl, Input, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

class RegistrationDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      name: "",
      passcode: "",
      passcode2: "",
      errors: {}
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.errors) {
      return {
        errors: props.errors
      }
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  registerNewAccount = e => {
    e.preventDefault();
    if(this.state.passcode !== this.state.passcode2){
      this.props.enqueueSnackbar("Passcode must match", { variant: "error", autoHideDuration: 2000 })
    }
    const newUser = {
      name: this.state.name.trim(),
      passcode: this.state.passcode.trim(),
    };

    var errorCallback = err => {
      this.props.enqueueSnackbar(err.data.message, { variant: "error", autoHideDuration: 2000 })
    }

    var successCallback = userData => {
      this.closeDialog()
      if(this.props.login){
        this.props.loginUser(userData, errorCallback)
      }
    }

    this.props.registerUser(newUser, successCallback, errorCallback);
  };

  openDialog = () => {
    this.setState({
      isOpen: true,
      name: "",
      passcode: "",
      passcode2: ""
    })
  }

  closeDialog = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { errors } = this.props;
    return (
      <div className={this.props.className}>
        <Button variant="contained" color="secondary" onClick={this.openDialog} fullWidth={this.props.fullWidth}>
          {this.props.buttonLabel}
        </Button>
        <Dialog open={this.state.isOpen} onClose={this.closeDialog} aria-labelledby="form-dialog-title">
          <form onSubmit={this.registerNewAccount}>
            <DialogTitle id="form-dialog-title">Create an Account</DialogTitle>
            <DialogContent>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name">Username</InputLabel>
                <Input id="name" name="name" autoComplete="username" autoFocus error={errors.name}
                  value={this.state.name} onChange={this.onChange} />
                <span className="red-text">{errors.name}</span>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="passcode">Passcode (Number Only)</InputLabel>
                <Input name="passcode" type="number" id="passcode" value={this.state.passcode}
                  onChange={this.onChange} error={errors.passcode} />
                <span className="red-text">{errors.passcode}</span>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="passcode2">Confirm Passcode (Number Only)</InputLabel>
                <Input name="passcode2" type="number" id="passcode2" value={this.state.passcode2}
                  onChange={this.onChange} error={errors.passcode2} />
                <span className="red-text">{errors.passcode2}</span>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained">
                Register
              </Button>
              <Button onClick={this.closeDialog} color="secondary" variant="contained">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  };
}

RegistrationDialog.propTypes = {
  registerUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(mapStateToProps, { registerUser, loginUser })((withSnackbar(RegistrationDialog)))