import React, { Component } from 'react'
import { connect } from "react-redux"
import axios from 'axios'
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import { withStyles, makeStyles, Grid, Paper, TextField, Typography, Link, Divider, Hidden, Button } from '@material-ui/core'

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SendIcon from '@material-ui/icons/Send';

const styles = theme => ({
  root: {
    height: "100%"
  },
  board: {
    width: '100%',

  },
  optionsBoard: {
    padding: theme.spacing(1)
  },
  inputArea: {
    alignItems: "center"
  },
  translateButton: {
    marginLeft: theme.spacing(1)
  }
})

class Translation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sentence: {},
      maxLength: 0,
      keyword: "",
      translation: '',
      prevTranslation: '',
      isTranslated: false,
      prevSentenceId: "",
      statistic: { users: [] }
    }
    this.getNewString()
    this.getStatistics()
  }

  parseForCodes = content => {
    let codes = ''
    const codeArr = content.match(/\{[\w\d\s#,.-]*\}|\[%[\w\d\s#,.-]*\]/g)
    if (codeArr) {
      codeArr.forEach(code => {
        codes += code + ' '
      });
    }
    return codes
  }

  getNewString = () => {
    axios.get(`/api/sentence/random?maxlength=${this.state.maxLength}&keyword=${this.state.keyword}`).then(res => {
      if (res.data)
        this.setState({
          sentence: res.data,
          translation: this.parseForCodes(res.data.content),
        })
    }).catch(error => {
      this.setState({
        sentence: {},
        translation: '',
      })
      console.log(error.response)
      this.props.enqueueSnackbar("Failed to retrieve new sentence", { variant: "error", autoHideDuration: 2000 })
    })
  }

  getPrevString = () => {
    if (!this.state.prevSentenceId) {
      return
    }
    axios.get(`/api/sentence/single/${this.state.prevSentenceId}`).then(res => {
      if (res.data)
        this.setState({
          sentence: res.data,
          translation: res.data.translations[0] ? res.data.translations[0]["content"] : "",
        })
    }).catch(error => {
      this.setState({
        sentence: {},
        translation: '',
      })
      console.log(error.response)
      this.props.enqueueSnackbar("Failed to retrieve sentence", { variant: "error", autoHideDuration: 2000 })
    })
  }

  sendTranslation = () => {
    const currentTranslation = this.state.translation
    const currentSentenceId = this.state.sentence._id
    if (!currentTranslation || currentTranslation.trim() === '' || !this.state.isTranslated) {
      return
    }

    if (!this.state.sentence._id) {
      this.getNewString()
      return
    }
    let options = {}
    if (this.state.maxLength) {
      options.maxLength = this.state.maxLength
    }
    if (this.state.keyword) {
      options.keyword = this.state.keyword
    }

    let data = {
      id: currentSentenceId,
      translation: currentTranslation.trim(),
      author: this.props.auth.user.name,
      options: options
    }
    axios.put('/api/sentence', data).then(res => {
      if (res.data) {
        this.setState({
          sentence: res.data,
          translation: this.parseForCodes(res.data.content),
          isTranslated: false,
          prevTranslation: currentTranslation,
          prevSentenceId: currentSentenceId
        })
        this.getStatistics()
      }
    }).catch(error => {
      console.log(error.response)
      this.props.enqueueSnackbar(error.response.data.message, { variant: "error", autoHideDuration: 2000 })
    })
  }

  getStatistics = () => {
    axios.get('/api/statistics?user=' + this.props.auth.user.name).then(res => {
      this.setState({ statistic: res.data })
    }).catch(err => {
      console.log(err.response)
    })
  }

  setMaxLength = (event) => {
    this.setState({ maxLength: event.target.value })
  }

  setKeyword = (event) => {
    this.setState({ keyword: event.target.value })
  }

  titleCase = () => {
    var splitStr = this.state.translation.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    this.setState({ translation: splitStr.join(' ') })
  }

  translating = (event) => {
    this.setState({ isTranslated: true, translation: event.target.value })
  }

  handleKeyPress = (target) => {
    if (target.charCode === 13) {
      target.preventDefault();
      this.sendTranslation()
    }
  }

  pastePrevTranslation = () => {
    this.setState({ translation: this.state.prevTranslation })
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row" className={classes.root} spacing={1}>
        <Grid container item direction="column" justifyContent="space-between" alignItems="stretch" xs={12} lg={9}>
          <Grid item className={classes.board}>
            <Sentence sentence={this.state.sentence} />
          </Grid>
          <Grid item >
            <Paper className={classes.optionsBoard}>
              <Grid container direction="column" spacing={1}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item xs={5} md={2}>
                    <TextField variant="outlined" label="Max length" size="small" type="number" value={this.state.maxLength} onChange={this.setMaxLength} />
                  </Grid>
                  <Grid item xs={5} md={2}>
                    <TextField variant="outlined" label="Keyword" size="small" value={this.state.keyword} onChange={this.setKeyword} />
                  </Grid>
                </Grid>
                <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item >
                    <Button color="secondary" aria-label="title" size="medium" variant="contained" onClick={this.titleCase}>
                      Title Case
                    </Button>
                  </Grid>
                  <Grid item >
                    <Button color="secondary" aria-label="paste" size="medium" variant="contained" onClick={this.pastePrevTranslation}>
                      Paste
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color="primary" aria-label="prev" size="medium" variant="contained" startIcon={<SkipPreviousIcon />} onClick={this.getPrevString}>
                      Prev
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color="primary" aria-label="skip" size="medium" variant="contained" endIcon={<SkipNextIcon />} onClick={this.getNewString}>
                      Skip
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Grid item container spacing={0} direction="row" alignItems="flex-end" className={classes.inputArea}>
              <Grid item xs>
                <TextField fullWidth maxRows="10" multiline variant="filled" label="Translate Here" value={this.state.translation}
                  onChange={this.translating}
                  onKeyPress={this.handleKeyPress} />
              </Grid>
              <Grid item >
                <Button variant="contained" size="large" color="primary" endIcon={<SendIcon />} className={classes.translateButton} onClick={this.sendTranslation}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid item lg={3} >
            <TranslationInformation statistic={this.state.statistic} />
          </Grid>
        </Hidden>
      </Grid>
    )
  }
}

Translation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(withSnackbar(withStyles(styles)(Translation)))

const sentenceStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
  },
  key: {
    background: '#5fba7d'
  }
}))

const informationStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    padding: theme.spacing(1),
    overflow: "auto"
  },
  divider: {
    margin: theme.spacing(2),
  }
}))

function Sentence(props) {
  const classes = sentenceStyles(props)
  let { key, content, context } = props.sentence
  return (
    <div>
      {key ?
        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start" key={key}>
          <Grid item>
            <Paper className={[classes.paper, classes.key].join(" ")}>
              <Typography variant="subtitle1">{key}</Typography>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.paper}>
              <Typography component="p" variant="body1" paragraph >{content}</Typography>
            </Paper>
          </Grid>
          {
            context ?
              <Grid item >
                <Paper className={classes.paper} >
                  <Typography component="p" variant="caption" >{context}</Typography>
                </Paper>
              </Grid>
              : null
          }
        </Grid>
        : null
      }
    </div>
  )
}

function TranslationInformation(props) {
  const classes = informationStyles(props)
  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">Information</Typography>
      <Typography variant="subtitle1">Total: {props.statistic.total}</Typography>
      <Typography variant="subtitle1">Translated: {props.statistic.translated}</Typography>
      <Typography variant="subtitle1">Percent: {Math.round(props.statistic.translated * 10000 / props.statistic.total, 2) / 100}%</Typography>
      <Typography variant="h6" color="textSecondary">
        <Link target="_blank" rel="noopener"
          href="https://fm-vn.com/diendan/showthread.php?30686-VH-FM14-H%C6%B0%E1%BB%9Bng-d%E1%BA%ABn-d%E1%BB%8Bch-v%C3%A0-gi%E1%BA%A3i-%C4%91%C3%A1p-th%E1%BA%AFc-m%E1%BA%AFc">
          Hướng dẫn dịch
        </Link>
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="h5">Top Heroes</Typography>
      {props.statistic.users.map((user, index) => (
        <Typography variant="subtitle1" key={index}>{(user.order) + '. '}{user.name}: {user.translated}</Typography>
      ))}
    </Paper>
  )
}
