import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Provider } from "react-redux"
import jwt_decode from "jwt-decode"

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createTheme from '@material-ui/core/styles/createTheme'
import { SnackbarProvider } from 'notistack'

import store from "./store"
import setAuthToken from "./utils/setAuthToken"
import { setCurrentUser, logoutUser } from "./actions/authActions"

import AppDrawer from './components/layouts/drawer'
import { Administration, ActivityLog, Translation, Accounts, Player, Chatbox, About, Download, Home, Server, FMVN } from './components/layouts/pages/index'

import { AbilityContext } from './components/auth/abilities-context'
import ability from './components/auth/ability'


// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  ability.update(decoded.rules)
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

const appTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#321255',
    },
    secondary: {
      main: '#96207d',
    },
  },
});

class App extends React.Component {
  render() {
    const routes = [
      {
        path: '/home',
        name: 'Home',
        icon: "",
        component: Home,
      },
      {
        path: '/fmvn',
        name: 'FMVN',
        icon: "",
        component: FMVN,
      },
      {
        path: '/download',
        name: 'Download',
        icon: "",
        component: Download,
      },
      {
        path: '/translation',
        name: 'Translation',
        icon: "",
        component: Translation,
      },
      {
        path: '/scouting',
        name: 'Scouting',
        icon: "",
        component: Player,
      },
      {
        path: '/chatbox',
        name: 'Chatbox',
        icon: "",
        component: Chatbox,
      },
      {
        path: '/about',
        name: 'About',
        icon: "",
        component: About,
      },
    ]

    const adminRoutes = [
      {
        path: '/accounts',
        name: 'Accounts',
        icon: "",
        component: Accounts,
      },
      {
        path: '/admin',
        name: 'Administration',
        icon: "",
        component: Administration,
      },
      {
        path: '/activity-log',
        name: 'Activity Log',
        icon: "",
        component: ActivityLog,
      },
      {
        path: '/server',
        name: 'Server',
        icon: "",
        component: Server,
      },
    ]

    return (
      <MuiThemeProvider theme={appTheme}>
        <Provider store={store}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <AbilityContext.Provider value={ability}>
              <BrowserRouter>
                <Switch>
                  <AppDrawer routes={routes} adminRoutes={adminRoutes} />
                </Switch>
              </BrowserRouter>
            </AbilityContext.Provider>
          </SnackbarProvider>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App
