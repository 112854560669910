import React from 'react'
import axios from 'axios'

import { withSnackbar } from 'notistack';

import {
    withStyles, makeStyles, Button, Paper, Typography, Divider, Box,
} from '@material-ui/core'

import { EventTracker } from '@devexpress/dx-react-chart';
import { Chart, LineSeries, ArgumentAxis, ValueAxis, Legend, Title, Tooltip, } from '@devexpress/dx-react-chart-material-ui';

import TimeRangePicker from '../time-range-picker'


const serverStyles = theme => ({
    paperPadding: {
        padding: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2),
    }
});

class Server extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }
    render() {
        const { classes } = this.props
        return (
            <div>
                <ServerUtilities enqueueSnackbar={this.props.enqueueSnackbar}></ServerUtilities>
                <Divider className={classes.divider} />
                <ServerMonitoring></ServerMonitoring>
            </div>
        )
    }
}

export default withSnackbar(withStyles(serverStyles)(Server))


const utilitiesStyles = makeStyles(theme => ({
    paperPadding: {
        padding: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
    }
}))

function ServerUtilities(props) {
    const classes = utilitiesStyles(props)

    function wakePcUp() {
        const secretKey = localStorage.getItem("secretKey")
        axios.put('/api/server/wakeup', {secretKey}).then(res => {
            props.enqueueSnackbar("Woke your PC up!", { variant: "success", autoHideDuration: 2000 })
        }).catch(error => {
            props.enqueueSnackbar("Something's not right!", { variant: "error", autoHideDuration: 2000 })
        })
    }

    return (
        <div>
            <Paper className={classes.paperPadding}>
                <Typography variant="h5">Utilities</Typography>
                <Button variant="contained" color="primary" onClick={wakePcUp} className={classes.button}>Wake My PC Up</Button>
            </Paper>
        </div>
    );
}


const monitoringStyles = () => ({
    legend: {
        display: "flex"
    },

})

const RootLegend = withStyles(monitoringStyles)(({ classes, ...restProps }) => (
    <div>
        <Legend.Root {...restProps} className={classes.legend} />
    </div>
));

class ServerMonitoring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hostname: "",
            timeRange: 15,
            uptime: 0,
            cpuMemData: [{ cpuValue: 0, memValue: 0, time: 0 }],
            nwData: [{ inValue: 0, outValue: 0, time: 0 }],
            tempData: [{ value: 0, time: 0 }],
        };
        this.retrieveServerStats(this.state.timeRange)
    }

    formatUptime = (timeInSeconds) => {
        let ut_sec = timeInSeconds
        let ut_min = ut_sec / 60;
        let ut_hour = ut_min / 60;

        ut_sec = Math.floor(ut_sec);
        ut_min = Math.floor(ut_min);
        ut_hour = Math.floor(ut_hour);

        ut_hour = ut_hour % 60;
        ut_min = ut_min % 60;
        ut_sec = ut_sec % 60;
        return `${ut_hour} hour(s) ${ut_min} minutes ${ut_sec} seconds`;
    }

    retrieveServerStats = (timeRange) => {
        axios.get(`/api/server/stats?timeRange=${timeRange}`).then(res => {
            if (res.data) {
                let cpuMemTemp = [], nwTemp = [], tempTemp = [], time
                res.data.states.forEach(element => {
                    time = Math.floor((Date.now() / 1000 - element.timestamp) / 60) // convert to X minutes ago
                    cpuMemTemp.push({ cpuValue: element.cpu, memValue: element.memory, time: time })
                    nwTemp.push({ inValue: element.network.megabitsIn, outValue: element.network.megabitsOut, time: time })
                    tempTemp.push({ value: element.temperature, time: time })
                })

                this.setState({
                    timeRange,
                    hostname: res.data.hostname, uptime: this.formatUptime(res.data.uptime),
                    cpuMemData: cpuMemTemp, nwData: nwTemp, tempData: tempTemp
                })
            }

        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return (
            <Box>
                <TimeRangePicker title="Monitoring" initialTimeRange={this.state.timeRange} callBack={this.retrieveServerStats} />
                <Box>
                    <br />
                    <Typography variant="h6">Hostname: {this.state.hostname}</Typography>
                    <Typography variant="h6">Uptime: {this.state.uptime}</Typography>
                    <Chart data={this.state.cpuMemData}>
                        <ValueAxis showLine />
                        <ArgumentAxis showGrid />
                        <LineSeries name="CPU" valueField="cpuValue" argumentField="time" />
                        <LineSeries name="Memory" valueField="memValue" argumentField="time" />
                        <Title text="CPU and Memory (%)" />
                        <Legend position="bottom" rootComponent={RootLegend} />
                        <EventTracker />
                        <Tooltip />
                    </Chart>
                    <Chart data={this.state.nwData}>
                        <ValueAxis showLine />
                        <ArgumentAxis showGrid />
                        <LineSeries name="In" valueField="inValue" argumentField="time" />
                        <LineSeries name="Out" valueField="outValue" argumentField="time" />
                        <Title text="Network (Mbps)" />
                        <Legend position="bottom" rootComponent={RootLegend} />
                        <EventTracker />
                        <Tooltip />
                    </Chart>
                    <Chart data={this.state.tempData}>
                        <ArgumentAxis showGrid />
                        <ValueAxis />
                        <LineSeries name="Temp" valueField="value" argumentField="time" />
                        <Title text="Temperature (oC)" />
                        <EventTracker />
                        <Tooltip />
                    </Chart>
                </Box>
            </Box>
        )
    }
}


