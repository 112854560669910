import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import {
  withStyles, LinearProgress, Button, Paper, Typography, Divider, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Box, TextField
} from '@material-ui/core'

import UserManager from './user-manager'


const styles = theme => ({
  translation: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  fileName: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2),
  },

});

class TranslationAdminComp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      loaded: 0,
      openAlertDialog: false
    }
  }

  handleSelectedFile = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    })

    this.props.enqueueSnackbar("Selected: " + event.target.files[0].name, { variant: "info", autoHideDuration: 2000 })
  }

  importDatabase = () => {
    this.setState({ openAlertDialog: false });

    const data = new FormData();
    data.append('file', this.state.selectedFile, this.state.selectedFile.name);
    data.append('secretKey', this.props.secretKey)
    axios.post('/api/import-database', data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total * 96),
        })
      },
    }).then(res => {
      if (res.status === 200)
        this.props.enqueueSnackbar("Importing translation database was successfully", { variant: "success", autoHideDuration: 2000 })
    }).catch(err => {
      this.props.enqueueSnackbar("Importing translation database has failed", { variant: "error", autoHideDuration: 2000 })
      console.log(err.response)
    }).finally(() => {
      this.setState({ loaded: 0 })
    });
  }

  handleExport = () => {
    this.setState({
      loaded: 25,
    })
    axios.post('/api/export-database', {secretKey: this.props.secretKey}, {
      onDownloadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
        })
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `translation-${Date(Date.now())}.txt`); //or any other extension
      document.body.appendChild(link);
      link.click();
      this.props.enqueueSnackbar("Exporting translation database was successfully", { variant: "success", autoHideDuration: 2000 })
    }).catch(err => {
      console.log(err.response)
      this.props.enqueueSnackbar("Failed to export translation database. See console log for more detail.", { variant: "error", autoHideDuration: 2000 })
    }).finally(() => {
      this.setState({ loaded: 0 })
    });
  }

  handleImport = () => {
    if (!this.state.selectedFile) {
      this.props.enqueueSnackbar("Select translation file first ", { variant: "warning", autoHideDuration: 2000 })
      return
    }
    this.setState({ openAlertDialog: true });
  };

  closeAlertDialog = () => {
    this.setState({ openAlertDialog: false });
  };

  clearTranslatingStatus = () => {
    axios.get('/api/sentence/clear', {
    }).then(res => {
      this.props.enqueueSnackbar("Clear Translating lines successfully!", { variant: "success", autoHideDuration: 2000 })
    }).catch(err => {
      console.log(err.response)
      this.props.enqueueSnackbar("Failed to clear translating lines!", { variant: "error", autoHideDuration: 2000 })
    }).finally(() => {
      this.setState({ loaded: 0 })
    });
  }

  render() {
    const { classes } = this.props

    return (
      <div>
        <Paper className={classes.translation} >
          <Typography variant="h5">Translation</Typography>
          <div>
            <input
              accept=""
              className={classes.input}
              id="contained-button-file-translation"
              type="file"
              onChange={this.handleSelectedFile}
            />
            <label htmlFor="contained-button-file-translation">
              <Button variant="contained" component="span" color="primary" className={classes.button}>Choose file</Button>
            </label>
            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleImport}>Import</Button>
            <Button variant="contained" className={classes.button} onClick={this.handleExport}>Export</Button>
            <Button variant="contained" color="secondary" className={classes.button} onClick={this.clearTranslatingStatus}>Clear Translating Lines</Button>
          </div>
          <Typography variant="subtitle2" className={classes.fileName}>Selected file: {this.state.selectedFile ? this.state.selectedFile.name : ""}</Typography>
          {this.state.loaded >= 0 ? <LinearProgress variant="determinate" value={this.state.loaded} color="secondary" /> : null}

          <Dialog
            open={this.state.openAlertDialog}
            onClose={this.closeAlertDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you sure that you want to import new translation database?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                When you import new translation database, the current database will be erased entirely. Be sure that you exported a backup!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.importDatabase} color="primary">Just do it</Button>
              <Button onClick={this.closeAlertDialog} color="secondary" variant="contained" >Back back please</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}


class ScoutingAdminComp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      loaded: 0,
    }
  }

  handleSelectedFile = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    })
    this.props.enqueueSnackbar("Selected: " + event.target.files[0].name, { variant: "info", autoHideDuration: 2000 })
  }

  handleImport = () => {
    if (!this.state.selectedFile) {
      this.props.enqueueSnackbar("Select players file first ", { variant: "warning", autoHideDuration: 2000 })
      return
    }

    const data = new FormData();
    data.append('file', this.state.selectedFile, this.state.selectedFile.name);
    data.append('secretKey', this.props.secretKey)
    axios.post('/api/import-players', data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total * 96),
        })
      },
    }).then(res => {
      if (res.status === 200)
        this.props.enqueueSnackbar("Importing player database was successfully", { variant: "success", autoHideDuration: 2000 })
    }).catch(err => {
      this.props.enqueueSnackbar("Importing player database has failed", { variant: "error", autoHideDuration: 2000 })
      console.log(err.response)
    }).finally(() => {
      this.setState({ loaded: 0 })
    });
  };

  render() {
    const { classes } = this.props

    return (
      <div>
        <Paper className={classes.translation} >
          <Typography variant="h5">Scouting</Typography>
          <div>
            <input
              accept=".json"
              className={classes.input}
              id="contained-button-file-scouting"
              type="file"
              onChange={this.handleSelectedFile}
            />
            <label htmlFor="contained-button-file-scouting">
              <Button variant="contained" component="span" color="primary" className={classes.button}>Choose file</Button>
            </label>
            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleImport}>Import</Button>
            <Button variant="contained" className={classes.button} onClick={this.handleExport}>Export</Button>
          </div>
          <Typography variant="subtitle2" className={classes.fileName}>Selected file: {this.state.selectedFile ? this.state.selectedFile.name : ""}</Typography>
          {this.state.loaded >= 0 ? <LinearProgress variant="determinate" value={this.state.loaded} color="secondary" /> : null}
        </Paper>
      </div>
    );
  }
}

const TranslationAdminPaper = withSnackbar(withStyles(styles)(TranslationAdminComp))
const ScoutingAdminPaper = withSnackbar(withStyles(styles)(ScoutingAdminComp))

export { TranslationAdminPaper, ScoutingAdminPaper }

class Administration extends Component {

  constructor(props) {
    super(props)
    this.state = {
      secretKey: "",
    }
  }

  updateSecretKey = (event) => {
    this.setState({ secretKey: event.target.value })
  }

  loadSecretKey = () => {
    this.setState({ secretKey: localStorage.getItem("secretKey") })
  }

  saveSecretKey = () => {
    localStorage.setItem("secretKey", this.state.secretKey)
  }

  componentDidMount() {
    this.loadSecretKey()
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Box >
          <TextField variant="filled" label="Secret Key" value={this.state.secretKey} onChange={this.updateSecretKey} />
          <Button color="primary" variant="contained" className={classes.button} onClick={this.saveSecretKey} >Save</Button>
        </Box>
        <Divider className={classes.divider} />
        <UserManager secretKey={this.state.secretKey} />
        <Divider className={classes.divider} />
        <TranslationAdminPaper secretKey={this.state.secretKey} />
        <Divider className={classes.divider} />
        <ScoutingAdminPaper secretKey={this.state.secretKey} />
      </div>
    )
  }
}

Administration.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withSnackbar(withStyles(styles)(Administration))