import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { Avatar, Button, CssBaseline, FormControl, Input, InputLabel, Paper, Typography, withStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { loginUser } from "../../../actions/authActions";
import RegistrationDialog from './../register'


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  registration: {
    width: '100%',
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      passcode: ""
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/home") // push user to home when they login
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to home
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/home")
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onLoginSubmit = e => {
    e.preventDefault();
    const userData = {
      name: this.state.name.trim(),
      passcode: this.state.passcode.trim()
    };
    var errorCallback = error => {
      this.props.enqueueSnackbar(error.data.message, { variant: "error", autoHideDuration: 2000 })
    }
    if (userData.passcode && userData.passcode.length === 4 )
      this.props.loginUser(userData, errorCallback);
    else
      this.props.enqueueSnackbar("Passcode is incorrect", { variant: "warning", autoHideDuration: 2000 })
  };

  render() {
    const { classes, errors } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">Sign in</Typography>
          <form className={classes.form} onSubmit={this.onLoginSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="Username">Username</InputLabel>
              <Input id="name" name="name" autoComplete="username" autoFocus
                value={this.state.name} onChange={this.onChange} error={errors.name} />
              <span className="red-text">{errors.name}</span>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="passcode">Passcode</InputLabel>
              <Input name="passcode" type="password" id="passcode" autoComplete="current-passcode"
                onChange={this.onChange} error={errors.passcode} />
              <span className="red-text">{errors.passcode}</span>
            </FormControl>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Sign in</Button>
          </form>
            <Typography >------------------------------</Typography>
            <RegistrationDialog className={classes.registration} fullWidth buttonLabel="Create an account" login />
        </Paper>
      </main>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps, { loginUser })(withStyles(styles)(withSnackbar(Login)));