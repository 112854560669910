import React from 'react'

import {
    withStyles, Paper, Typography, Grid, IconButton, Input,
    FormControl, Radio, RadioGroup, FormControlLabel, Box, withWidth,
} from '@material-ui/core'

import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined'

const styles = theme => ({
    paperPadding: {
        padding: theme.spacing(2),
    },
})

function convertDaysToMins(numberOfDays){
    return numberOfDays*24*60
}

class TimeRangePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            timeRange: props.initialTimeRange,
            customTimeRange: ""
        }
    }

    changeTimeRange = (event) => {
        let timeRange = +event.target.value
        if (+timeRange === 0) {
            timeRange = convertDaysToMins(this.state.customTimeRange)
        }
        this.setState({ timeRange: +event.target.value })
        this.props.callBack(timeRange)
    }

    onCustomTimeRangeInputChange = (event) => {
        if (!isNaN(event.target.value)) {
            this.setState({ timeRange: 0, customTimeRange: +event.target.value })
        }
    }

    handleKeyPress = (target) => {
        this.setState({ timeRange: 0 })
        if (target.charCode === 13) {
            target.preventDefault()
            this.props.callBack(convertDaysToMins(this.state.customTimeRange))
        }
    }

    render() {
        const { classes } = this.props
        return (
            <Box>
                <Paper className={classes.paperPadding}>
                    <Typography variant="h5">{this.props.title}</Typography>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <IconButton aria-label="back" color="secondary" onClick={() => this.props.callBack(this.state.timeRange)}>
                                <RefreshOutlinedIcon fontSize="large"></RefreshOutlinedIcon>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="timeRange" name="Time Range" value={this.state.timeRange} onChange={this.changeTimeRange}>
                                    <FormControlLabel value={15} control={<Radio />} label="15 Mins" />
                                    <FormControlLabel value={30} control={<Radio />} label="30 Mins" />
                                    <FormControlLabel value={60} control={<Radio />} label="60 Mins" />
                                    <FormControlLabel value={480} control={<Radio />} label="8 Hours" />
                                    <FormControlLabel value={1440} control={<Radio />} label="24 Hours" />
                                    <FormControlLabel value={0} control={<Radio />}
                                        label={(<Input
                                            placeholder="Days Ago"
                                            onChange={this.onCustomTimeRangeInputChange}
                                            value={this.state.customTimeRange}
                                            onKeyPress={this.handleKeyPress}
                                        />)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid>
                            {this.props.children}
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        )
    }
}

export default withStyles(styles)(withWidth()(TimeRangePicker))

