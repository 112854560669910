
import React from 'react';
import { Route, Link, Switch, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import {
  AppBar, Button, CssBaseline, Divider, Drawer, Hidden, IconButton, List, ListItem,
  ListItemText, Toolbar, Typography, withStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import PrivateRoute from "../auth/PrivateRoute";
import { logoutUser } from "../../actions/authActions";
import { Can } from '../auth/abilities-context'
import Login from './pages/login'

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      height: "100vh"
    },
    [theme.breakpoints.down('md')]: {
      height: "90vh"
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  userName: {
    padding: theme.spacing(2)
  },
  drawerPaper: {
    width: drawerWidth,
  },
  screen: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      width: `100%`,
    },
    height: "100%",
  },
  content: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  },
  logoutButton: {
    position: "absolute",
    bottom: 0,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: `calc(100% - ${theme.spacing(2)}px)`
  }
});

class AppDrawer extends React.Component {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  logout = () => {
    this.props.logoutUser();
    window.location.reload();
  }

  render() {
    const { window, classes, theme, auth } = this.props;
    let currentLocationName = this.props.routes.find(route => route.path === this.props.location.pathname)
    currentLocationName = currentLocationName ? currentLocationName : this.props.adminRoutes.find(route => route.path === this.props.location.pathname)

    const drawer = (
      <div>
        <div className={[classes.toolbar, classes.userName].join(" ")} >
          <Typography component="h1" variant="h5" align="center" justify="center" >
            Hi, {auth.user.name}
          </Typography>
        </div>
        <Divider />
        <List>
          {this.props.routes.map((item, index) => (
            <Can I="view" a={item.name} key={item.name}>
              <ListItem button component={Link} to={item.path} onClick={this.state.mobileOpen ? this.handleDrawerToggle : null}>
                <ListItemText primary={item.name} />
              </ListItem>
            </Can>
          ))}
        </List>
        <Divider />
        <Can I="view" a="admin tools">
          <List>
            {this.props.adminRoutes.map((item, index) => (
              <ListItem key={item.name} button component={Link} to={item.path} onClick={this.state.mobileOpen ? this.handleDrawerToggle : null}>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Can>
        {!auth.isAuthenticated ?
          <List>
            <ListItem button component={Link} to='/login' onClick={this.state.mobileOpen ? this.handleDrawerToggle : null}>
              <ListItemText primary='Log In' />
            </ListItem>
          </List>
          :
          <Button variant="contained" color="primary" className={classes.logoutButton} onClick={this.logout}>Log Out</Button>}
      </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (

      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" aria-label="Open drawer" onClick={this.handleDrawerToggle} className={classes.menuButton} >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              {currentLocationName ? currentLocationName.name : "FMVN"}
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{ paper: classes.drawerPaper, }} >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer classes={{ paper: classes.drawerPaper, }} variant="permanent" open >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.screen}>
          <div className={classes.toolbar} />
          <div className={classes.content}>
            <Switch>
              {this.props.routes.map((item, index) => (
                <PrivateRoute path={item.path} component={item.component} name={item.name} key={item.name} />
              ))}

              {this.props.adminRoutes.map((item, index) => (
                <PrivateRoute path={item.path} component={item.component} name={item.name} key={item.name} />
              ))}
              <Redirect exact from="/" to="/home" />
              <Route path='/login' component={Login} />
            </Switch>
          </div>
        </main>
      </div>
    );
  }
}

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { logoutUser })(withStyles(styles, { withTheme: true })(AppDrawer));