import React from 'react'

class Chatbox extends React.Component {

  render() {
    return (
      <div>
        <iframe title="discord" src="https://discord.com/widget?id=539083886324482049&theme=dark" width="350" height="500" allowtransparency="true" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
      </div>
    )
  }
}

export default (Chatbox)