
import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Home, Login } from "./../layouts/pages/index"
import ability from './ability'

const PrivateRoute = ({ component: Component, name, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      ability.can('view', name) ? (
        <Component {...props} />
      ) : (auth.isAuthenticated ? (
        <Home />
      ) : (
        <Login />
      ))
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);