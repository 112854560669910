import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import {
  withStyles, Box, Button, Paper, Typography, Dialog,
  DialogActions, DialogContent, DialogTitle,
  Table, TableHead, TableRow, TableCell, TableBody, TableContainer
} from '@material-ui/core'

import RegistrationDialog from '../../register'

const styles = theme => ({
  content: {
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex"
  }

});

class UserManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAccountsDialogOpen: false,
      unapprovedUsers: [],
    }
  }

  openAccountsDialog = () => {
    axios.get(`/api/users/unapproved`).then(res => {
      if (res.data) {
        res.data.forEach(user => {
          user.hidden = false
        })
        this.setState({ unapprovedUsers: res.data })
      }
    }).catch(error => {
      if (error) {
        this.props.enqueueSnackbar(`Failed to retrieve unapproved-accounts:`, { variant: "error", autoHideDuration: 2000 })
      }
    })
    this.setState({ isAccountsDialogOpen: true })
  }

  closeAccountsDialog = () => {
    this.setState({ isAccountsDialogOpen: false })
  }

  approveUser = (index) => {
    let bodyData = { action: "approve", secretKey: this.props.secretKey }
    let users = this.state.unapprovedUsers
    let userName = users[index].name
    axios.put(`/api/users/${userName}`, bodyData).then(res => {
      users[index].hidden = true
      this.setState({ unapprovedUsers: users })
    }).catch(error => {
      if (error) {
        this.props.enqueueSnackbar(`Failed to approve: ${userName}`, { variant: "error", autoHideDuration: 2000 })
      }
    })
  }

  deleteUser = (index) => {
    let bodyData = { action: "delete", secretKey: this.props.secretKey }
    let users = this.state.unapprovedUsers
    let userName = users[index].name
    axios.put(`/api/users/${userName}`, bodyData).then(res => {
      users[index].hidden = true
      this.setState({ unapprovedUsers: users })
    }).catch(error => {
      if (error) {
        this.props.enqueueSnackbar(`Failed to delete: ${userName}`, { variant: "error", autoHideDuration: 2000 })
      }
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Paper className={classes.content}>
          <Typography variant="h5">User Manager</Typography>
          <Box className={classes.buttonGroup}>
            <RegistrationDialog buttonLabel="Create an Account" className={classes.button} />
            <Button variant="contained" color="secondary" onClick={this.openAccountsDialog} className={classes.button}>
              Approve Accounts
            </Button>
            <Dialog open={this.state.isAccountsDialogOpen} onClose={this.closeAccountsDialog} fullWidth>
              <DialogTitle id="form-dialog-title">Approve Accounts</DialogTitle>
              <DialogContent >
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="accounts table" height={5}>
                    <TableHead>
                      <TableRow>
                        <TableCell size="medium">Name</TableCell>
                        <TableCell align="right">Actons</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.unapprovedUsers.map((item, index) =>
                        item.hidden ? null :
                          <TableRow key={index} >
                            <TableCell>{item.name}</TableCell>
                            <TableCell align="right">
                              <Button color="primary" variant="contained" className={classes.button} onClick={() => this.approveUser(index)}>Approve</Button>
                              <Button color="secondary" variant="contained" className={classes.button} onClick={() => this.deleteUser(index)}>Delete</Button>
                            </TableCell>
                          </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeAccountsDialog} color="primary" variant="contained">OK</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Paper>
      </div>
    );
  }
}

UserManager.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withStyles(styles)(UserManager))