import React from 'react'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import {
    withStyles, Box, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Button
} from '@material-ui/core'


import TimeRangePicker from '../time-range-picker'

const styles = () => ({
    root: {
        maxHeight: "88vh",
        display: "flex",
        flexDirection: "column",
    },
})

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class ActivityLog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeRange: 1440,
            records: [],
            sortDirection: "desc"
        }
        this.retrieveActivitiesLog(this.state.timeRange)
    }

    retrieveActivitiesLog = (timeRange) => {
        const startTime = Math.floor(Date.now() / 1000 - timeRange * 60)
        axios.get(`/api/activities?starttime=${startTime}`).then(res => {
            if (res.data) {
                this.setState({
                    records: res.data
                })
            }

        }).catch(error => {
            this.props.enqueueSnackbar(error.message, { variant: "error", autoHideDuration: 2000 })
        })
    }

    changeSortDirection = () => {
        this.setState({ sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc" })
    }

    clearActivities = () => {
        axios.delete(`/api/activities`).then(res => {
            this.props.enqueueSnackbar("Successfully cleared activities!", { variant: "success", autoHideDuration: 2000 })
        }).catch(error => {
            this.props.enqueueSnackbar(error.message, { variant: "error", autoHideDuration: 2000 })
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Box className={classes.root}>
                <TimeRangePicker title="Activity Controls" initialTimeRange={this.state.timeRange} callBack={this.retrieveActivitiesLog} >
                    <Button color="secondary" variant="contained" onClick={this.clearActivities}>Clear</Button>
                </TimeRangePicker>
                <TableContainer>
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={this.state.sortDirection} width="20%">
                                    <TableSortLabel active={true} direction={this.state.sortDirection} onClick={this.changeSortDirection}>Time</TableSortLabel>
                                </TableCell>
                                <TableCell width="20%">Type</TableCell>
                                <TableCell width="60%">Detail</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(this.state.records, getComparator(this.state.sortDirection, "timestamp")).map((item, index) =>
                                <TableRow key={index} hover >
                                    <TableCell>{new Date(item.timestamp * 1000).toLocaleString("vi-VN")}</TableCell>
                                    <TableCell>{item.type}</TableCell>
                                    <TableCell>{item.detail}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }
}

export default withSnackbar(withStyles(styles)(ActivityLog))

