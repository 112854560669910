import React from 'react'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class About extends React.Component {
  render() {
    return (
      <div>
        <Paper style={{ padding: "7px" }}>
          <Typography variant="h5" color="textPrimary" >About</Typography>
          <Divider style={{margin: "10px"}} />
          <Typography variant="body1" color="textPrimary" gutterBottom>
            This is just an app for non-profit using purpose. All the data is contributed by FM fans.
            </Typography>
        </Paper>
      </div>
    )
  }
}

export default (About)