import React from 'react'
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const mainStyles = theme => ({
  content: {
    width: '100%',
    height: "100%",
  },
})

class FMVNFrame extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.content}>
        <iframe title="FMVN" src="https://news.fm-vn.com/" width="100%" height="100%"></iframe>
      </div>
    )
  }
}

FMVNFrame.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(mainStyles)(FMVNFrame)